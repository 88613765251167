import React from 'react';
import './Content.css';

const Content: React.FC = () => {
    return (
        <main>
            <div className="Content">
                <h2>EVOLVIT SUPPORT</h2>
                <h3>Klicka på ikonerna för att ladda hem våra supportverktyg</h3>
                <br />
                <div className="horizontalLine" />
                <br />
                <div className="iconContainer">
                    <a href="https://get.teamviewer.com/evolvitquick" className="iconLink">
                        <div className="col">
                            <img className="thumbnail" src="/teamviewersnabb.png" alt="Teamviewer Snabbhjälp" />
                            <h3>Teamviewer snabbhjälp</h3>
                            <p className="fadedText">Windows / Mac</p>
                        </div>
                    </a>
                    <a href="/EvolvitTV.exe" className="iconLink">
                        <div className="col">
                            <img className="thumbnail" src="/teamviewerbl.png" alt="Teamviewer Supportavtal" />
                            <h3>Teamviewer supportavtal</h3>
                            <p className="fadedText">Windows</p>
                        </div>
                    </a>
                </div>
            </div>
        </main>
    );
};

export default Content;
