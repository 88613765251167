import React from 'react';
import './Footer.css';

const Footer: React.FC = () => {
    return (
        <footer>
            <div className="footerContent">
                <div className="footerColumn">
                    <h3>Epost:</h3>
                    <a href="mailto:support@evolvit.se">support@evolvit.se</a>
                    <p>Vardagar 07.00 - 17.00</p>
                </div>
                <div className="footerColumn">
                    <h3>Telefon:</h3>
                    <a href="tel:020555555">020-55 55 55</a>
                    <p>Vardagar 07.00 - 17.00</p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
